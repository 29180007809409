<template>
    
    <div class="banner-view">

        <h3 v-if="bannerTitle != undefined && bannerTitle.length > 0">
            {{ bannerTitle.toUpperCase() }}
            <el-tooltip v-if="bannerTitleTooltip != undefined && bannerTitleTooltip.length > 0" class="box-item" effect="dark" :content="bannerTitleTooltip" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
            </el-tooltip>
        </h3>

        <el-carousel height="150px" :interval="5000" type="card" :autoplay="true" indicator-position="none">
            <el-carousel-item style="padding: 5px; border: 1px solid #409eff;" v-for="item in sortedBanners" :key="item">
                <div v-if="item !== null && item.symbol !== null && item.symbol !== undefined" class="item">
                    <img v-if="item.logoURL !== null && item.logoURL !== undefined" class="img" :src="getImageUrl(item)" width="80" height="80" @click="goToCompanyDetails(item.symbol)">
                    <p class="carousel-item-company-name" @click="goToNews(item.link)"> {{ getCompanyName(item) }} </p>
                    <p class="carousel-item-news-date" @click="goToNews(item.link)"> {{ formatDate(item) }} </p>
                    <p class="carousel-item-headline" @click="goToNews(item.link)"> {{ formatText(item.headline, 50) }} </p>
                </div>
            </el-carousel-item>
        </el-carousel>

    </div>

</template>

<script>

    import { BLANK_PAGE_ARG } from '../constants/AppConstants';
    import { COMPANY_DETAILS_PATH_WITHOUT_PARAM } from '../constants/RoutesConstants';

    export default {
        name: 'banner-view',
        props: {
            bannerTitle: String,
            bannerTitleTooltip: String,
            banners: Array,
            imagePrefix: String
        },
        methods: {
            getImageUrl(item) {
                return this.imagePrefix + item.logoURL;
            },
            goToCompanyDetails(name) {
                const formattedUrl = COMPANY_DETAILS_PATH_WITHOUT_PARAM + "/" + name.toLowerCase();
                window.open(formattedUrl, BLANK_PAGE_ARG).focus();
            },
            goToNews(link) {
                window.open(link, BLANK_PAGE_ARG).focus();
            },
            formatDate(item) {

                const date = new Date(item.timestamp * 1000)
                const yyyy = date.getFullYear();
                let mm = date.getMonth() + 1;
                let dd = date.getDate();

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                return dd + '/' + mm + '/' + yyyy + " " + item.time;

            },
            formatText(text, limit) {

                if(window.screen.width >= 1024) {
                    return text;
                }

                return text !== null && text.length > limit ? text.substr(0, limit) + "..." : text;

            },
            getCompanyName(item) {
                return item.companyName !== null && item.companyName !== undefined ? item.symbol + " - " + this.formatText(item.companyName, 30) : item.symbol;
            }
        },
        computed: {
            currentPage() {
                return this.pathsIndexs[this.$route.path];
            },
            sortedBanners() {

                let sortedBanners = [];
                Object.assign(sortedBanners, this.banners);

                return sortedBanners.sort((b1, b2) => b1.timestamp < b2.timestamp);

            }
        }
    }

</script>

<style lang="scss" scoped>

    @media screen and (max-width: 1024px) {

        .el-carousel__item h3 {
            font-size: 8px;
        }

        .carousel-item-company-name {
            font-size: 10px;
            font-weight: bold;
            margin: 0px;
        }

        .carousel-item-news-date {
            font-weight: bold;
            font-size: 8px;
            margin: 0px;
        }

        .carousel-item-headline {
            color: white;
            font-weight: bold;
            font-size: 8px;
            margin-top: 5px;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .el-carousel__item h3 {
            font-size: 13px;
        }

        .carousel-item-company-name {
            font-size: 13px;
            font-weight: bold;
            margin: 0px;
        }

        .carousel-item-news-date {
            font-weight: bold;
            font-size: 11px;
            margin: 0px;
        }

        .carousel-item-headline {
            color: white;
            font-weight: bold;
            font-size: 11px;
            margin-top: 5px;
        }

    }

    .banner-view {
        width: 100%;
        margin-right: 5px;
    }

    .img {
        margin: 5px;
        border-radius: 5px;
    }

    .el-carousel__item {
        border-radius: 5px;
    }

    .el-carousel__item h3 {
        color: #409eff;
        background-color: $default-secondary-color;
        line-height: 200px;
        margin: 5px;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
        font-weight: bold;
    }
    
    .el-carousel__item:nth-child(2n) {
        background-color: $default-secondary-color;
        color: #409eff;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
        font-weight: bold;
    }
    
    .el-carousel__item:nth-child(2n+1) {
        background-color: $default-secondary-color;
        color: #409eff;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
        font-weight: bold;
    }

    .el-carousel--horizontal {
        overflow-x: clip;
    }

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

</style>
