<template>

  <div class="dashboard-summary-section">
    <el-tooltip class="box-item" effect="dark" content="Atualizar resumos" placement="top">
      <el-button type="primary" class="refresh" :disabled="refreshButtonDisabled" @click="getSummary()" @mouseover="mouseOverRefresh = true" @mouseleave="mouseOverRefresh = false">
        <el-icon><Refresh/></el-icon>
      </el-button>
    </el-tooltip>
    <div :class="getMouseOverRefresClass" v-loading="mouseOverRefresh" class="table-responsive" :element-loading-svg="loadingSvg" element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

      <el-skeleton :loading="tableLoading" animated>

        <template #template>
          
          <h3>
              DESTAQUES
              <el-tooltip class="box-item" effect="dark" content="Destaques da carteira" placement="top">
                  <el-icon class="info-icon"><InfoFilled/></el-icon>
              </el-tooltip>
          </h3>
          <div class="skeleton-row" style="width: 100%;">
            <el-skeleton-item variant="image" style="width: 100%; height: 150px; margin-top: 10px; border-radius: 5px;"/>
            <el-skeleton-item variant="image" style="width: 100%; height: 170px; margin-top: 10px; border-radius: 5px;"/>
            <el-skeleton-item variant="image" style="width: 100%; height: 150px; margin-top: 10px; border-radius: 5px;"/>
          </div>

          <h3 style="margin-top: 100px;">
            HISTÓRICO
            <el-tooltip class="box-item" effect="dark" content="Histórico da carteira" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
            </el-tooltip>
          </h3>
          <el-skeleton-item variant="button" style="width: 12%; height: 22px;"/>
          <el-skeleton-item variant="text" style="width: 100%; height: 250px;"/>

          <h3 style="margin-top: 100px;">
            CARTEIRA
            <el-tooltip class="box-item" effect="dark" content="Resumo dos ativos por categorias" placement="top">
              <el-icon class="info-icon"><InfoFilled/></el-icon>
            </el-tooltip>
          </h3>
          <el-skeleton-item variant="text" style="width: 100%; height: 250px;"/>
          <el-skeleton-item variant="button" style="width: 30px; height: 30px; margin-top: 10px;"/>

          <h3 style="margin-left: 10px;">
            Proventos
            <el-tooltip class="box-item" effect="dark" content="Resumo dos ativos por categorias" placement="top">
              <el-icon class="info-icon"><InfoFilled/></el-icon>
            </el-tooltip>
          </h3>
          <el-skeleton-item variant="button" style="width: 60px; height: 20px; margin-left: 10px;"/>
          <el-skeleton-item variant="text" style="width: 100%; height: 30px; margin-top: 10px;"/>
          <el-skeleton-item variant="text" style="width: 100%; height: 30px;"/>
          <el-skeleton-item variant="text" style="width: 100%; height: 30px;"/>
          <el-skeleton-item variant="text" style="width: 100%; height: 250px;"/>
          <el-skeleton-item variant="text" style="width: 100%; height: 30px;"/>
          <el-skeleton-item variant="text" style="width: 100%; height: 30px;"/>
          <el-skeleton-item variant="text" style="width: 100%; height: 30px;"/>

          <h3 style="margin-top: 100px;">
            COMPOSIÇÃO
            <el-tooltip class="box-item" effect="dark" content="Resumo do total investido" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
            </el-tooltip>
          </h3>
          <div class="skeleton-row" style="width: 100%;">

            <div style="width: 50%;">

              <el-skeleton-item variant="text" style="width: 100%; height: 30px;"/>
              <el-skeleton-item variant="text" style="width: 100%; height: 30px;"/>
              <el-skeleton-item variant="text" style="width: 100%; height: 30px;"/>
              <el-skeleton-item variant="text" style="width: 100%; height: 30px;"/>
              <el-skeleton-item variant="text" style="width: 100%; height: 30px;"/>
              <el-skeleton-item variant="text" style="width: 100%; height: 30px;"/>
              <el-skeleton-item variant="text" style="width: 100%; height: 30px;"/>

            </div>

            <div style="width: 50%;">
              <el-skeleton-item variant="circle" style="width: 300px; height: 300px;"/>
            </div>

          </div>
          
        </template>
        
        <template #default>

          <section-row :isFirstSection="true">
            <company-summary-banner-section v-if="allListsLoaded" :companiesSummary="summary.companiesSummary"/>
          </section-row>

          <section-row>
            <capital-summary-section v-if="allListsLoaded" :tableLoading="tableLoading" :capitalSummary="summary.capitalSummary"/>
          </section-row>
          
          <section-row>
            <company-summary-section v-if="allListsLoaded" :tableLoading="tableLoading" :companiesSummary="summary.companiesSummary" isUserSummary="true" :withDividends="withDividends">
              <template #dividends-switch>
                <el-switch v-model="withDividends" style="margin-left: 5px;" @change="getSummary()"></el-switch>
              </template>
            </company-summary-section>
          </section-row>

          <section-row>
            <wallet-summary-section v-if="allListsLoaded" :tableLoading="tableLoading" :walletSummary="summary.walletSummary"/>
          </section-row>

        </template>

      </el-skeleton>

    </div>
  </div>

</template>

<script>

import SectionRow from '@/component/common/SectionRow';
import CompanySummaryBannerSection from '@/section/dashboard/tab/summary/items/CompanySummaryBannerSection';
import CapitalSummarySection from '@/section/dashboard/tab/summary/items/CapitalSummarySection';
import WalletSummarySection from '@/section/dashboard/tab/summary/items/WalletSummarySection';
import CompanySummarySection from '@/section/dashboard/tab/summary/items/CompanySummarySection';
import { getSummary } from '@/http/bff/dashboard-bff-service';
import { DEFAULT_LOADING_SVG } from '@/constants/AppConstants';

export default {
  name: 'dashboard-summary-section',
  components: { SectionRow, CompanySummaryBannerSection, CapitalSummarySection, WalletSummarySection, CompanySummarySection },
  data() {
    return {
      tableLoading: false,
      allListsLoaded: false,
      summary: {
        walletSummary: {
          walletType: [],
          walletTypesPendingTotal: 0,
          walletTypesTotal: 0,
          walletTotal: 0
        },
        companiesSummary: {
          companiesTypes: []
        },
        capitalSummary: {
          capitalMonthCapitalType: []
        }
      },
      refreshButtonDisabled: false,
      mouseOverRefresh: false,
      loadingSvg: DEFAULT_LOADING_SVG,
      withDividends: false
    }
  },
  methods: {
    getSummary() {

      this.summary = {
        walletSummary: {
          walletType: [],
          walletTypesPendingTotal: 0,
          walletTypesTotal: 0,
          walletTotal: 0
        },
        companiesSummary: {
          companiesTypes: []
        },
        capitalSummary: {
          capitalMonthCapitalType: []
        }
      };

      this.refreshButtonDisabled = true;
      this.tableLoading = true;
      this.mouseOverRefresh = true;

      getSummary(this.withDividends).then(response => {

        this.summary = response.data;
        this.tableLoading = false;
        this.allListsLoaded = true;
        this.refreshButtonDisabled = false;
        this.mouseOverRefresh = false;
        this.emitter.emit('setTypeData', this.summary.companiesSummary.companiesTypes);

      }).catch(() => {
        this.tableLoading = false;
        this.refreshButtonDisabled = false;
        this.mouseOverRefresh = false;
      });
    }
  },
  computed: {
    getMouseOverRefresClass() {
      return this.mouseOverRefresh === true ? "mouse-on-refresh" : "mouse-off-refresh";
    }
  },
  created() {
    this.getSummary();
  }
}
</script>

<style lang="scss" scoped>

  .dashboard-summary-section {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .refresh {
    color: $default-button-color;
    height: 30px;
    width: 30px;
    margin-left: 5px;
    margin-top: 20px;
  }

  .mouse-off-refresh {
    opacity: 1;
  }

  .mouse-on-refresh {
    opacity: 0.5;
  }

</style>