<template>

  <confirm-dialog :visible="isRefreshUserBalancesDialogOpen"
      headerText="Realmente deseja ajustar o balanço?"
      confirmText="Confirmar"
      cancelText="Cancelar"
      @confirm="refreshUserBalances()"
      @cancel="closeDialog()">
      <template #body>
          <span> Você pode atualizar o balanço somente uma vez por dia, realmente deseja prosseguir com a atualização? </span>
      </template>
  </confirm-dialog>

  <div class="wallet">

    <h3>
      CARTEIRA
      <el-tooltip class="box-item" effect="dark" content="Resumo dos ativos por categorias" placement="top">
        <el-icon class="info-icon"><InfoFilled/></el-icon>
      </el-tooltip>
    </h3>

    <div class="wallet-table">

      <div class="wallet-bar">

        <div class="bar">
          <Bar :data="getData" :options="options" />
        </div>

      </div>

      <div v-if="isUserSummary" class="wallet-table-title">

        <el-tooltip class="box-item" effect="dark" content="Atualizar balanço dos ativos" placement="top">
          <el-button type="success" class="refresh" @click="isRefreshUserBalancesDialogOpen = true">
            <el-icon><Setting/></el-icon>
          </el-button>
        </el-tooltip>

      </div>

      <div class="filter-table">
        <div>
          <span class="filter-title"> Proventos </span>
          <el-tooltip class="box-item" effect="dark" content="Adiciona proventos (12M) recebidos nos cálculos" placement="top">
            <el-icon class="info-icon filter-title"><InfoFilled/></el-icon>
          </el-tooltip>
        </div>
        <slot name="dividends-switch"></slot>
      </div>
      
      <div v-if="companiesSummary.companiesTypes.length > 0 && inRequest === false" class="collapse">
        
        <table class="total-table">

          <thead>

            <tr>
              <th style="width: 25%"> Patrimônio </th>
              <th style="width: 25%"> Investido </th>
              <th style="width: 25%"> {{ walletDifferenceText }} </th>
              <th style="width: 25%"> Hoje </th>
            </tr>

          </thead>

          <tbody>

            <tr>

              <td v-check-sensitive-data> 
                <div class="table-data">
                  <el-tooltip class="box-item" effect="dark" content="Patrimônio" placement="top">
                    <table-information :bgcolor="'#f2e33a'" :bcolor="'#f2e33a'"/>
                  </el-tooltip>
                  <span> {{ getPtFormattedPrice(walletPatrimony) }} </span>
                </div>
              </td>

              <td v-check-sensitive-data>
                <div class="table-data">
                  <el-tooltip class="box-item" effect="dark" content="Investido" placement="top">
                    <table-information :bgcolor="'#409eff'" :bcolor="'#409eff'"/>
                  </el-tooltip>
                  <span> {{ getPtFormattedPrice(walletTotal) }} </span>
                </div>
              </td>

              <td>
                <el-icon v-if="walletDifferenceTotal < 0 && !isPhoneScreenWidth" class="under-limit"><CaretBottom/></el-icon>
                <el-icon v-if="walletDifferenceTotal >= 0 && !isPhoneScreenWidth" class="above-limit"><CaretTop/></el-icon>
                <span v-check-sensitive-data :class="getDifferenceClass(CompanyType.ACAO.value, walletDifferenceTotal)">
                  {{ getPtFormattedPrice(walletDifferenceTotal).replace("-", "") }}
                </span>
                <span class="type-percentage type-total" :class="getDifferenceClass(CompanyType.ACAO.value, walletDifferenceTotal)">
                  {{ walletPatrimonyDifferencePercentage.replace("-", "") }}
                </span>
              </td>

              <td>
                <el-icon v-if="walletOpenDifferenceTotal() < 0 && !isPhoneScreenWidth" class="under-limit"><CaretBottom/></el-icon>
                <el-icon v-if="walletOpenDifferenceTotal() >= 0 && !isPhoneScreenWidth" class="above-limit"><CaretTop/></el-icon>
                <span v-check-sensitive-data :class="getDifferenceClass(CompanyType.ACAO.value, walletOpenDifferenceTotal())">
                  {{ getPtFormattedPrice(walletOpenDifferenceTotal()).replace("-", "") }}
                </span>
                <span class="type-percentage type-total" :class="getDifferenceClass(CompanyType.ACAO.value, walletOpenDifferenceTotal())">
                  {{ walletOpenDifferenceTotalPercentage().replace("-", "") }}
                </span>
              </td>

            </tr>

          </tbody>

        </table>

        <el-collapse v-model="activeNames">

          <div v-for="companyType in companyByTypeList" :key="companyType">

            <el-collapse-item :id="companyType" :name="companyType" :key="companyType">

              <template #title>
                <div class="collapse-title">

                  <div class="collapse-border type-title" :class="getCompanyTypeClassName(companyType)">
                    <span :class="getCompanyTypeClassName(companyType)"> {{ getCompanyTypeDescription(companyType) }} </span>
                    <span class="companies-number"> {{ getCompanyTypeLength(companyType) }} ativos </span>
                  </div>

                  <div class="types-total">

                    <div v-if="!isPhoneScreenWidth">

                      <div class="table-data">

                        <el-tooltip class="box-item" effect="dark" content="Patrimônio" placement="top">
                          <table-information :bgcolor="'#f2e33a'" :bcolor="'#f2e33a'"/>
                        </el-tooltip>
                        <span v-check-sensitive-data class="type-total"> {{ getPtFormattedPrice(this.typeData[companyType].typeCapitalTotal) }} </span>
                        <span class="type-percentage type-total"> {{ this.typeData[companyType].typeCapitalPercentage }} </span>

                        <span style="margin-left: 5px; margin-right: 10px;"> - </span>

                        <el-tooltip class="box-item" effect="dark" content="Investido" placement="top">
                          <table-information :bgcolor="'#409eff'" :bcolor="'#409eff'"/>
                        </el-tooltip>
                        <span v-check-sensitive-data class="type-total"> {{ getPtFormattedPrice(this.typeData[companyType].typeTotal) }} </span>
                        <span class="type-percentage type-total"> {{ this.typeData[companyType].typePercentage }} </span>

                        <span style="margin-left: 5px;"> = </span>

                      </div>

                    </div>

                    <el-icon v-if="this.typeData[companyType].typeDifferenceTotal < 0" style="margin-left: 5px;" class="under-limit"><CaretBottom/></el-icon>
                    <el-icon v-if="this.typeData[companyType].typeDifferenceTotal >= 0" style="margin-left: 5px;" class="above-limit"><CaretTop/></el-icon>
                    <el-tooltip class="box-item" effect="dark" :content="walletTypeDifferenceText(this.typeData[companyType].typeDifferenceTotal)" placement="top">
                      <span v-check-sensitive-data class="default-info type-total" :class="getDifferenceClass(companyType, this.typeData[companyType].typeDifferenceTotal)">
                        {{ getPtFormattedPriceWithSymbol(this.typeData[companyType].typeDifferenceTotal).replace("-", "") }}
                      </span>
                    </el-tooltip>
                    <span class="type-percentage default-info type-total" :class="getDifferenceClass(companyType, this.typeData[companyType].typeDifferenceTotal)">
                      {{ this.typeData[companyType].typeDifferencePercentage.replace("-", "") }}
                    </span>

                  </div>

                  <div class="types-total">

                    <el-icon v-if="this.typeData[companyType].typeOpenDifferenceTotal < 0" class="under-limit"><CaretBottom/></el-icon>
                    <el-icon v-if="this.typeData[companyType].typeOpenDifferenceTotal >= 0" class="above-limit"><CaretTop/></el-icon>
                    <el-tooltip class="box-item" effect="dark" content="Hoje" placement="top">
                      <span v-check-sensitive-data class="default-info type-total" :class="getDifferenceClass(companyType, this.typeData[companyType].typeOpenDifferenceTotal)">
                        {{ getFormattedValue(this.typeData[companyType].typeOpenDifferenceTotal, CompanyType.ACAO.value).replace("-", "") }}
                      </span>
                    </el-tooltip>
                    <span class="type-percentage default-info type-total" :class="getDifferenceClass(CompanyType.ACAO.value, this.typeData[companyType].typeOpenDifferenceTotal)">
                      {{ this.typeData[companyType].typeOpenDifferencePercentage.replace("-", "") }}
                    </span>

                  </div>

                </div>
              </template>
              
              <table class="table-collapse" :class="{ 'with-dividends': withDividends }" v-loading="this.typeData[companyType].loading" element-loading-text="Carregando..." :element-loading-svg="loadingSvg" element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

                <thead>

                  <tr>
                    <th> Nome </th>
                    <th> Quantidade </th>
                    <th> Preço Atual </th>
                    <th> Preço Investido</th>
                    <th> Patrimônio </th>
                    <th v-if="withDividends === true"> Patrimônio com proventos </th>
                    <th> Investido </th>
                    <th> Diferença </th>
                    <th v-if="withDividends === true"> Diferença com proventos </th>
                    <th> Hoje </th>
                    <th> % na categoria </th>
                    <th v-if="withDividends === true"> % na categoria com proventos </th>
                    <th> % na carteira </th>
                    <th v-if="withDividends === true"> % na carteira com proventos </th>
                  </tr>

                </thead>

                <tbody>

                    <tr v-for="company in typeData[companyType].companies" :key="company.id">

                      <td @click="goToCompanyDetails(company)" class="name-column"> 
                        <div class="image-name">
                          <img v-if="company.imageUrl != null" style="margin-right: 10px" :src="company.imageUrl" width="30" height="20">
                          <span :class="getCompanyTagTypeClassName(company.type)" style="cursor: pointer;"> {{ company.name }} </span>
                        </div>
                      </td>

                      <td>
                          <span v-check-sensitive-data class="default-info"> {{ getFormattedAmount(company) }} </span>
                      </td>

                      <td>
                        <div class="table-data">
                          <el-tooltip class="box-item" effect="dark" content="Patrimônio" placement="top">
                            <table-information :bgcolor="'#f2e33a'" :bcolor="'#f2e33a'"/>
                          </el-tooltip>
                          <span v-check-sensitive-data> {{ getFormattedValue(company.currentPrice, company.type) }} </span>
                        </div>
                      </td>

                      <td>
                        <div class="table-data">
                          <el-tooltip class="box-item" effect="dark" content="Investido" placement="top">
                            <table-information :bgcolor="'#409eff'" :bcolor="'#409eff'"/>
                          </el-tooltip>
                          <span v-check-sensitive-data> {{ getFormattedValue(company.price, company.type) }} </span>
                        </div>
                      </td>

                      <td>
                        <div class="table-data">
                          <el-tooltip class="box-item" effect="dark" content="Patrimônio" placement="top">
                            <table-information :bgcolor="'#f2e33a'" :bcolor="'#f2e33a'"/>
                          </el-tooltip>
                          <span v-check-sensitive-data> {{ getFormattedValue(company.capital, company.type) }} </span>
                        </div>
                      </td>

                      <td v-if="withDividends === true">
                        <div class="table-data">
                          <el-tooltip class="box-item" effect="dark" content="Investido" placement="top">
                            <table-information :bgcolor="'#409eff'" :bcolor="'#409eff'"/>
                          </el-tooltip>
                          <span v-if="company.capitalWithDividends !== null" v-check-sensitive-data> {{ getFormattedValue(company.capitalWithDividends, company.type) }} </span>
                          <span v-if="company.capitalWithDividends === null">-</span>
                        </div>
                      </td>

                      <td>
                        <div class="table-data">
                          <el-tooltip class="box-item" effect="dark" content="Investido" placement="top">
                            <table-information :bgcolor="'#409eff'" :bcolor="'#409eff'"/>
                          </el-tooltip>
                          <span v-check-sensitive-data> {{ getFormattedValue(company.balance, company.type) }} </span>
                        </div>
                      </td>

                      <td :class="getDifferenceClass(company.type, company.priceDifference)">
                        <el-icon v-if="company.priceDifference < 0" class="under-limit"><CaretBottom/></el-icon>
                        <el-icon v-if="company.priceDifference >= 0" class="above-limit"><CaretTop/></el-icon>
                        <span v-check-sensitive-data :class="getDifferenceClass(company.type, company.priceDifference)" class="default-info"> {{ getDifference(company) }} </span>
                        <span class="type-percentage default-info type-total"> {{ company.priceDifferencePercentage }} </span>
                      </td>

                      <td v-if="withDividends === true" :class="getDifferenceClass(company.type, company.priceDifferenceWithDividends)">
                        <div v-if="company.priceDifferenceWithDividends !== null">
                          <el-icon v-if="company.priceDifferenceWithDividends < 0" class="under-limit"><CaretBottom/></el-icon>
                          <el-icon v-if="company.priceDifferenceWithDividends >= 0" class="above-limit"><CaretTop/></el-icon>
                          <span v-check-sensitive-data :class="getDifferenceClass(company.type, company.priceDifferenceWithDividends)" class="default-info"> {{ getDifferenceWithDividends(company) }} </span>
                          <span class="type-percentage default-info type-total"> {{ company.priceDifferencePercentageWithDividends }} </span>
                        </div>
                        <span v-if="company.priceDifferenceWithDividends === null">-</span>
                      </td>

                      <td :class="getDifferenceClass(company.type, company.openPriceDifference)">
                        <el-icon v-if="company.openPriceDifference < 0" class="under-limit"><CaretBottom/></el-icon>
                        <el-icon v-if="company.openPriceDifference >= 0" class="above-limit"><CaretTop/></el-icon>
                        <span v-check-sensitive-data :class="getDifferenceClass(company.type, company.openPriceDifference)" class="default-info"> {{ getOpenPriceDifference(company) }} </span>
                        <span class="type-percentage default-info type-total"> {{ company.openPriceDifferencePercentage }} </span>
                      </td>

                      <td>
                        <span v-if="company.status === CompanyStatus.ACTIVE.value"> {{ company.typeTotalPercentage }} </span>
                        <span v-if="company.status === CompanyStatus.INACTIVE.value"> - </span>
                      </td>

                      <td v-if="withDividends === true">
                        <span v-if="company.status === CompanyStatus.ACTIVE.value"> {{ company.typeTotalPercentageWithDividends }} </span>
                        <span v-if="company.typeTotalPercentageWithDividends === null"> - </span>
                      </td>

                      <td>
                        <span v-if="company.status === CompanyStatus.ACTIVE.value"> {{ company.walletTotalPercentage }} </span>
                        <span v-if="company.status === CompanyStatus.INACTIVE.value"> - </span>
                      </td>

                      <td v-if="withDividends === true">
                        <span v-if="company.status === CompanyStatus.ACTIVE.value"> {{ company.walletTotalPercentageWithDividends }} </span>
                        <span v-if="company.walletTotalPercentageWithDividends === null"> - </span>
                      </td>

                    </tr>

                </tbody>

              </table>

            </el-collapse-item>

          </div>

        </el-collapse>
        <div v-if="companiesSummary.companiesTypes.length === 0">
          <EmptyResult></EmptyResult>
        </div>

      </div>

      <div v-if="companiesSummary.companiesTypes.length > 0" class="table-info">
        <table-information :bgcolor="'#f2e33a'" :bcolor="'#f2e33a'" :text="'Patrimônio'"/>
        <table-information :bgcolor="'#409eff'" :bcolor="'#409eff'" :text="'Investido'"/>
        <table-information :bgcolor="'green'" :bcolor="'green'" :text="'Lucro'"/>
        <table-information :bgcolor="'red'" :bcolor="'red'" :text="'Prejuízo'"/>
        <table-information v-if="withDividends === true" :bgcolor="'gray'" :bcolor="'gray'" :text="'Proventos'"/>
      </div>

    </div>
    
  </div>

</template>

<script>

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'
import { DEFAULT_STOCK_DECIMAL, COMMA, DOT, DEFAULT_COMPANY_IMAGE_NAME, DEFAULT_LOADING_SVG, BLANK_PAGE_ARG } from '@/constants/AppConstants';
import { COMPANY_DETAILS_PATH_WITHOUT_PARAM} from '@/constants/RoutesConstants';
import { getFormattedValue } from '@/util/MoneyUtils';
import { refreshUserBalances } from '@/http/bff/dashboard-bff-service';
import CompanyStatus from '@/constants/CompanyStatus';
import CompanyType from '@/constants/CompanyType';
import EmptyResult from '@/component/common/EmptyResult';
import TableInformation from '@/component/common/TableInformation';
import { Setting } from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus'
import ConfirmDialog from "@/component/common/ConfirmDialog";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
  name: 'company-summary-section',
  components: { ConfirmDialog, EmptyResult, TableInformation, Bar, Setting },
  props: {
    tableLoading: Boolean,
    companiesSummary: Object,
    isUserSummary: Boolean,
    withDividends: Boolean
  },
  data() {
    return {
      DEFAULT_COMPANY_IMAGE_NAME,
      typeData: {},
      CompanyStatus,
      CompanyType,
      page: 1,
      activeNames: "ACAO",
      loadingSvg: DEFAULT_LOADING_SVG,
      data: {
        labels: [ "Patrimônio", "Investido", "Lucro/Prejuízo" ],
        datasets: []
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      },
      isRefreshUserBalancesDialogOpen:  false,
      inRequest: true
    }
  },
  created() {
    this.getTypeCompanies(this.companiesSummary.companiesTypes);
    this.emitter.on('setTypeData', (companiesTypes) => {
      this.getTypeCompanies(companiesTypes);
    });
  },
  methods: {
    closeDialog() {
      this.isRefreshUserBalancesDialogOpen = false;
    },
    refreshUserBalances() {

      const body = {};
      refreshUserBalances(body).then(() =>{
        ElMessage({
          message: 'Os balanços serão atualizados em breve, por favor aguarde!',
          type: 'success'
        });
        this.isRefreshUserBalancesDialogOpen = false;
      }).catch(error => {

        if(error.response.status === 422) {
          ElMessage({
            message: 'Você atingiu o limite diário de atualizações, por favor tente novamente amanhã!',
            type: 'error'
          });
        } else {
          ElMessage({
            message: 'Os balanços não puderam ser atualizados, tente novamente mais tarde!',
            type: 'error'
          });
        }
        
        this.isRefreshUserBalancesDialogOpen = false;

      });

    },
    getCompanyTypeColor(companyType) {
      return CompanyType[companyType].color;
    },
    getCompanyTypeDescription(companyType) {
      return CompanyType[companyType].description;
    },
    getCompanyTypeClassName(companyType) {
      return CompanyType[companyType].className;
    },
    getCompanyTagTypeClassName(companyType) {
      return CompanyType[companyType].tagTypeClassName;
    },
    getCompanyTypeLength(companyType) {
      return this.typeData[companyType].companies != null && this.typeData[companyType].companies != undefined ?
            this.typeData[companyType].companies.length :
            0;
    },
    getFormattedValue(value, type) {

      if(type !== null && type !== undefined) {
        const locale = CompanyType[type].locale;
        return getFormattedValue(value, locale);
      }

      return "-";

    },
    getDifferenceClass(type, value) {

      if(value === null) {
        return "";
      }

      if(type !== null && type !== undefined) {
        return value >= 0 ? "above-limit" : "under-limit";
      }

      return "";

    },
    getDifference(company) {

      if(company.type !== null && company.type !== undefined) {

        const locale = CompanyType[company.type].locale;

        return getFormattedValue(company.priceDifference, locale);

      }

      return "-";

    },
    getDifferenceWithDividends(company) {

      if(company.type !== null && company.type !== undefined && company.priceDifferenceWithDividends !== null) {

        const locale = CompanyType[company.type].locale;

        return getFormattedValue(company.priceDifferenceWithDividends, locale);

      }

      return "-";

    },
    getOpenPriceDifference(company) {

      if(company.type !== null && company.type !== undefined) {

        const locale = CompanyType[company.type].locale;

        return getFormattedValue(company.openPriceDifference, locale);

      }

      return "-";

    },
    getFormattedAmount(company) {

      if(company.type !== null && company.type === CompanyType.STOCK.value) {
        return company.amount.toFixed(DEFAULT_STOCK_DECIMAL).replace(DOT, COMMA);
      }

      return company.amount;

    },
    refreshPage() {
      this.$router.go();
    },
    getTypeCompanies(companiesTypes) {

      companiesTypes.forEach(ct => {

        const companyType = ct.type;
        this.typeData[companyType] = {
          companies: ct.companies,
          loading: false,
          typeTotal: ct.typeTotal,
          typeDifferenceTotal: ct.typeDifferenceTotal,
          typePercentage: ct.typePercentage,
          typeCapitalTotal: ct.typeCapitalTotal,
          typeCapitalPercentage: ct.typeCapitalPercentage,
          typeDifferencePercentage: ct.typeDifferencePercentage,
          typeOpenDifferencePercentage: ct.typeOpenDifferencePercentage,
          typeOpenDifferenceTotal: ct.typeOpenDifferenceTotal
        };
        this.setGraphicInfo(companyType);
        this.inRequest = false;

      });

    },
    setGraphicInfo(companyType) {

      const difference = this.typeData[companyType].typeTotal + this.typeData[companyType].typeDifferenceTotal;
      const label = this.getCompanyTypeDescription(companyType);
      const color = this.getCompanyTypeColor(companyType);
      const profit = this.typeData[companyType].typeDifferenceTotal;
      const data = [
        difference,
        this.typeData[companyType].typeTotal,
        profit
      ]

      const dataset = {
        label,
        backgroundColor: [ color, color, color, color ],
        data
      };
      this.data.datasets.push(dataset);

    },
    getType(type) {
      return CompanyType[type].description;
    },
    getStatusText(status) {
      return status === null ? "Todos" : CompanyStatus[status].description;
    },
    getTypeText(type) {
      return type === null ? "Todos" : CompanyType[type].description;
    },
    getPtFormattedPriceWithSymbol(price) {

      let formattedPrice = this.getPtFormattedPrice(price);
      if(this.isPhoneScreenWidth) {
        return formattedPrice;
      }

      return price > 0 ? "+ " + formattedPrice : "- " + formattedPrice.substring(1, formattedPrice.length - 1);

    },
    getPtFormattedPrice(price) {
      
      if(price !== null && price !== undefined) {
        return getFormattedValue(price, "pt_br");
      }

      return "-";

    },
    goToCompanyDetails(company) {
      const formattedUrl = COMPANY_DETAILS_PATH_WITHOUT_PARAM + "/" + company.name.toLowerCase();
      window.open(formattedUrl, BLANK_PAGE_ARG).focus();
    },
    walletOpenDifferenceTotal() {
      return Object.keys(this.typeData).map(type => this.typeData[type].typeOpenDifferenceTotal).reduce((partialSum, a) => partialSum + a, 0);
    },
    walletOpenDifferenceTotalPercentage() {

      const percentagesFormatted = Object.keys(this.typeData).map(type => {

        const typeOpenDifferenceTotal = this.typeData[type].typeOpenDifferenceTotal;
        const priceWithOpenValue = this.walletPatrimony + typeOpenDifferenceTotal;
        const percentage = (typeOpenDifferenceTotal * 100)/priceWithOpenValue;

        const percentageFormatted = percentage.toString().substring(0, percentage.toString().length - 1).replace(COMMA, DOT);

        return Number.parseFloat(percentageFormatted);

      });

      return percentagesFormatted.reduce((partialSum, a) => partialSum + a, 0).toFixed(2).replace(DOT, COMMA) + "%";

    },
    walletTypeDifferenceText(value) {
      return value > 0 ? "Lucro" : value === 0 ? "Diferença" : "Prejuízo"
    }
  },
  computed: {
    getData() {
      return this.data;
    },
    companyByTypeList() {
      return Object.keys(this.typeData);
    },
    statusList() {
      let statusList = Object.keys(CompanyStatus);
      return statusList.filter(status => !this.filter.selectedStatus.includes(status));
    },
    walletDifferenceText() {
      const difference = Object.keys(this.typeData).map(type => this.typeData[type].typeDifferenceTotal).reduce((partialSum, a) => partialSum + a, 0); 
      return difference > 0 ? "Lucro" : difference === 0 ? "Diferença" : "Prejuízo"
    },
    walletDifferenceTotal() {
      return Object.keys(this.typeData)
        .map(type => this.typeData[type].typeDifferenceTotal != null && this.typeData[type].typeDifferenceTotal)
        .reduce((partialSum, a) => partialSum + a, 0);
    },
    walletTotal() {
      return Object.keys(this.typeData)
        .map(type => this.typeData[type].typeTotal != null && this.typeData[type].typeTotal)
        .reduce((partialSum, a) => partialSum + a, 0); 
    },
    walletPatrimony() {
      return this.walletTotal + this.walletDifferenceTotal;
    },
    walletPatrimonyDifferencePercentage() {
      return (((this.walletPatrimony * 100) / this.walletTotal) - 100).toFixed(2).replace(DOT, COMMA) + "%";
    },
    isPhoneScreenWidth() {
      return window.screen.width < 1024;
    },
    getWithDividends() {
      return this.withDividends !== null && this.withDividends !== undefined ? this.withDividends : this.dividends;
    }
  }
}
</script>

<style lang="scss" scoped>

  .balance-input {
    text-align: center;
    width: fit-content;
  }

  .collapse-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: bold;
  }

  .types-total {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  .wallet-table {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    display: flex;
    flex-direction: column;
  }
  
  .collapse {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .patrimony-info {
    color: #f2e33a;
    font-weight: bold;
  }

  .capital-info {
    color: #409eff;
    font-weight: bold;
  }

  .default-info {
    font-weight: bold;
  }

  .patrimony-info-table {
    color: #f2e33a;
    font-weight: bold;
  }

  .patrimony-dividends-info-table {
    color: grey;
    font-weight: bold;
  }

  .capital-info-table {
    color: #409eff;
    font-weight: bold;
  }

  .default-info-table {
    font-weight: bold;
  }

  .type-title {
    display: inline;
  }

  .el-collapse {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .refresh {
    color: $default-button-color;
    height: 30px;
    width: 30px;
    margin-left: 5px;
    margin-top: 20px;
  }

  @media screen and (max-width: 1024px) {

    .wallet {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .companies-number {
      font-size: 7px;
      margin-left: 5px;
    }

    .type-percentage {
      font-size: 7px !important;
      font-style: italic;
      padding-bottom: 6px;
    }

    .wallet-bar {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      height: 15%;
    }

    .acao-company-type-class {
      width: 30% !important;
      font-size: 9px;
    }

    .fii-company-type-class {
      width: 30% !important;
      font-size: 9px;
    }

    .stock-company-type-class {
      width: 30% !important;
      font-size: 6px;
    }

    .bdr-company-type-class {
      width: 30% !important;
      font-size: 9px;
    }

    .fiagro-company-type-class {
      width: 30% !important;
      font-size: 9px;
    }

    .etf-company-type-class {
      width: 30% !important;
      font-size: 9px;
    }

    .table-collapse {
      width: 100%;
      display: block;
      font-size: 8px;
      overflow: auto;
    }

    .types-total {
      font-size: 7px;
      width: 35%;
    }

    .type-total {
      margin: 5px;
      margin-left: 1px;
      font-size: 7px;
    }

    .header-info {
      font-size: 7px;
    }

    .collapse {
      width: 100%;
    }

    .total-table {
      display: block;
      width: 100%;
      overflow: auto;
    }

    .currency-tag {
      width: 100px !important;
    }

    .statistics {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .statistic-item {
      margin: 5px;
    }

    .one {
      margin-right: 5px;
    }

    .bar {
      height: 300px !important;
    }

  }

  @media screen and (min-width: 1024px) and (min-width: 1920px) {

    .wallet {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .companies-number {
      font-size: 11px;
      margin-left: 5px;
    }

    .type-percentage {
      font-size: 10px !important;
      font-style: italic;
      padding-bottom: 10px;
    }

    .wallet-bar {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 50px;
      margin-top: 30px;
      height: 250px;
    }

    .acao-company-type-class {
      width: 17% !important;
    }

    .fii-company-type-class {
      width: 17% !important;
    }

    .stock-company-type-class {
      width: 17% !important;
    }

    .bdr-company-type-class {
      width: 17% !important;
    }

    .fiagro-company-type-class {
      width: 17% !important;
    }

    .etf-company-type-class {
      width: 17% !important;
    }

    .types-total {
      font-size: 13px;
    }

    .type-total {
      margin: 5px;
      margin-left: 2px;
      margin-right: 2px;
      font-size: 13px;
    }

    .general-header-info {
      width: 100%;
    }

    .collapse {
      width: 100%;
    }

    .currency-tag {
      width: 150px !important;
    }

    .statistics {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .statistic-item {
      margin: 20px;
    }

    .one {
      margin-right: 100px;
    }

    .bar {
      height: 100%;
    }

  }

  .image-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .bar {
    width: 100%;
  }

  table {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .wallet-table-title {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .name-column {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .table-tag {
    width: 90%;
  }

  .with-dividends {
    width: 100%;
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .filter-table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 10px;
  }

  .filter-title {

    font-weight: bold;
    color: $default-primary-title-text-color;

  }

  .table-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

</style>

